import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { ClassroomModule } from './classroom/classroom.module';
import { AccountModule } from './account/account.module';
import { OrganizationModule } from './organization/organization.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoadingInterceptor } from './services/loading.interceptor';
import { InviteModule } from './invite/invite.module';
import { MatNativeDateModule } from '@angular/material/core';
import { NotFoundModule } from './not-found/not-found.module';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MainNavComponent } from './dashboard/main-nav/main-nav.component';
import { HelpModule } from './help/help.module';
import { SeatUiModule } from 'src/app/seat-ui/seat-ui.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { getLocalePaginatorIntl } from './locale-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ClassroomUiModule } from './classroom-ui/classroom-ui.module';
import { AuthModule } from './auth/auth.module';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgMapsPlacesModule } from '@ng-maps/places';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { MapsAPILoader, NgMapsCoreModule } from '@ng-maps/core';
import { MatIconModule } from '@angular/material/icon';
import { UpgradeNowSectionComponent } from './dashboard/upgrade-now-section/upgrade-now-section.component';
import { MatListModule } from '@angular/material/list';
import { registerLocaleData } from '@angular/common';
import localeKeys from './locales/locale-keys';

registerLocaleData(localeKeys, 'en-keys');

@NgModule({ declarations: [
        AppComponent,
        MainNavComponent,
        PrintLayoutComponent,
        UpgradeNowSectionComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        ClassroomModule,
        ClassroomUiModule,
        AccountModule,
        AuthModule,
        InviteModule,
        MatSidenavModule,
        MatNativeDateModule,
        NotFoundModule,
        TooltipModule,
        MatSelectModule,
        HelpModule,
        SeatUiModule,
        NgbModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        NgMapsCoreModule,
        NgMapsPlacesModule.forRoot({
            autocomplete: {}
        }),
        NgMapsGoogleModule,
        MatIconModule,
        MatListModule], providers: [
        { provide: MatPaginatorIntl, useValue: getLocalePaginatorIntl() },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: GOOGLE_MAPS_API_CONFIG,
            useValue: {
                apiKey: environment.googleMapApiKey,
                libraries: ['places']
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
