<div class="language-toolbar-area">
    <app-toolbar-language [whiteStyle]="false"></app-toolbar-language>
</div>
<app-theme-switch></app-theme-switch>
<app-divided-page>
    <div left-pane>
        <div class="register-text">
            <div>
                <h1 class="text-white" i18n="@@register-page--left-panel-title">Start your learning journey for free</h1>
                <ul class="text-white font-semibold mt-2">
                    <li>
                        <svg class="checkmark"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="none"
                             viewBox="0 0 24 24"
                             stroke-width="1.5"
                             stroke="currentColor"
                             aria-hidden="true">
                            <path stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"></path>
                        </svg> <span i18n="@@register-page--left-panel-line1">No credit card required</span>
                    </li>
                    <li>
                        <svg class="checkmark"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="none"
                             viewBox="0 0 24 24"
                             stroke-width="1.5"
                             stroke="currentColor"
                             aria-hidden="true">
                            <path stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"></path>
                        </svg> <span i18n="@@register-page--left-panel-line2">Unlimited demo access to all apps</span>
                    </li>
                    <li>
                        <svg class="checkmark"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="none"
                             viewBox="0 0 24 24"
                             stroke-width="1.5"
                             stroke="currentColor"
                             aria-hidden="true">
                            <path stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"></path>
                        </svg> <span i18n="@@register-page--left-panel-line3">Foster positive outcomes with expert-created learning tools</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container right-pane-container"
         right-pane>
        <div class="register-form auth-page">

            <app-error-message-area [error]="errorObject"></app-error-message-area>

            <app-base-login-register-form [formType]="'register'"
                                          [showLogo]="true"
                                          floatLabel="auto"
                                          [showRegisterMessage]="true"
                                          [smallAlternateLogin]="false"
                                          (onServiceLoginClick)="onServiceLoginClick($event)"
                                          #commonForm>
                                          <div class="actions" actions>

                                            <span class="spacer"></span>
                            
                            
                                            <button mat-flat-button
                                                    color="primary"
                                                    class="register-button custom-primary-button"
                                                    [disabled]="!commonForm.acceptedTerms || (wasClickedOnce && (loading.isLoading || (loading.loadingChange | async)))"
                                                    (click)="submitForm(commonForm.baseLoginRegisterForm)">
                                                <span i18n="@@common-register">
                                                    Register
                                                </span>
                                            </button>
                                        </div>
            </app-base-login-register-form>




        </div>
        <div class="form-footer">

            <div>
                <a class="login-register-footer-link"
                   routerLink="/login"
                   i18n="@@register-form-already-have-account">Already have an account?</a>
            </div>



        </div>
    </div>
</app-divided-page>